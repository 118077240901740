import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import SEO from '../components/seo'
import DonnaBF from '../assets/images/donnaandbf.jpg'
import ScheduleSection from '../components/ScheduleSection'

class AboutPage extends React.Component {
  render() {
    const siteTitle = this.props.data.site.siteMetadata.title
    const siteDescription = this.props.data.site.siteMetadata.description

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title="Colorado Springs Dating Scene - Single Men & Women"
          description={siteDescription}
        />
        <div className="about-page page-heading text-center">
          <div className="container">
            <h1 className="text-white m-0">About Donna</h1>
            <hr />
            <h4 className="text-white m-0">On a Mission</h4>
          </div>
        </div>
        <div className="page-content">
          <div className="container">
            <div className="row justify-content-around">
              <div className="col-md-9">
                <h3 className="text-center mt-0">My Mission</h3>
                <p className="subheading font-italic text-center">
                  To be a hands-on highly personalized advocate for my clients,
                  to help them find their perfect match, whether for dating, a
                  long term committed relationship or marriage.
                </p>
                <hr />
                <h2 className="text-center">
                  ABOUT DONNA & PERFECTLY MATCHED DATING
                </h2>
                <div className="row py-3 align-items-center">
                  <div className="col-md-6 order-md-2">
                    <div className="text-center">
                      <figure>
                        <img
                          src={DonnaBF}
                          alt="Donna and BF"
                          className="img-fluid"
                        />
                      </figure>
                    </div>
                  </div>
                  <div className="col-md-6 order-md-1">
                    <p>
                      I am an Air Force brat and a Baby Boomer. I was raised
                      with two brothers and one sister and our family traveled
                      the world with my dad's Air Force career. I grew up in
                      Texas, Goose Bay Labrador, Mississippi, Louisiana,
                      Edinburgh, Scotland, Lakenheath, England and Cape Cod,
                      Massachusetts, in that order.
                    </p>
                  </div>
                </div>
                <p>
                  I am divorced with two wonderful daughters and five amazing
                  grandsons. I am in a relationship with the man I consider to
                  be the love of my life, and that makes me a lucky woman.
                </p>
                <p>
                  In 1987 when my 23-year marriage ended I found myself going
                  through a divorce and considering what I might do to support
                  myself. Not in a million years did I think it would be
                  matchmaking or the dating business. As it turns out, that was
                  my first and only job, and I decided to make it a career by
                  opening my own business, Perfectly Matched, on October 6,
                  1991.
                </p>
                <p>
                  In 1995 at my high school class reunion I received an award
                  for the "Person Who Chose the Most Obvious Career Path". While
                  I never thought of myself as a "matchmaker", I did it all
                  through high school and my 23-year marriage. I would even use
                  the word "match" when I would set up two people. It has become
                  my passion to help those in the Colorado Springs dating scene
                  find true love and their best match.
                </p>
                <p>
                  I guess you could say I found my niche at 40, and 30 years
                  later I cannot begin to explain how this journey has changed
                  my life. I feel so incredibly fortunate to be able to affect
                  my clients in an area of their lives that is so important, and
                  I take that very seriously.
                </p>
                <p>
                  At Perfectly Matched I have over 700 single men and women who
                  have married and many more than that in long term
                  relationships who do not choose to marry. I have a profile
                  test and a proven system to help my clients in the Colorado
                  Springs dating scene find that one special person they have
                  not been able to find on their own. I do as much coaching and
                  counseling as I do matchmaking, and I have gained a unique
                  perspective on dating and creating lasting, healthy
                  relationships which I share with each client as we look to
                  find their perfect match.
                </p>

                <div className="text-center pt-5">
                  <hr />
                  <h3 className="text-center">My Radio Commercials</h3>
                  <div className="row justify-content-around">
                    <div className="col-md-6 mb-4">
                      <p className="subheading mb-0">Voracious Reader</p>
                      <audio controls>
                        <source
                          src="https://res.cloudinary.com/ds1cf1g5j/video/upload/v1576462294/commercials/spot-1_uj4zq6.mp3"
                          type="audio/mp3"
                        />
                        <track srclang="eng" label="English" kind="captions" />
                        Your browser does not support the audio element.
                      </audio>
                    </div>
                    <div className="col-md-6 mb-4">
                      <p className="subheading mb-0">Financially Independent</p>
                      <audio controls>
                        <source
                          src="https://res.cloudinary.com/ds1cf1g5j/video/upload/v1576462471/commercials/spot-2_kt87tv.mp3"
                          type="audio/mp3"
                        />
                        <track srclang="eng" label="English" kind="captions" />
                        Your browser does not support the audio element.
                      </audio>
                    </div>
                    <div className="col-md-6 mb-4">
                      <p className="subheading mb-0">Drop Dead Beautiful</p>
                      <audio controls>
                        <source
                          src="https://res.cloudinary.com/ds1cf1g5j/video/upload/v1576462592/commercials/spot-3_xdmpfp.mp3"
                          type="audio/mp3"
                        />
                        <track srclang="eng" label="English" kind="captions" />
                        Your browser does not support the audio element.
                      </audio>
                    </div>
                    <div className="col-md-6 mb-4">
                      <p className="subheading mb-0">No Preconceived Notions</p>
                      <audio controls>
                        <source
                          src="https://res.cloudinary.com/ds1cf1g5j/video/upload/v1576462639/commercials/spot-4_dnzdxx.mp3"
                          type="audio/mp3"
                        />
                        <track srclang="eng" label="English" kind="captions" />
                        Your browser does not support the audio element.
                      </audio>
                    </div>
                    <div className="col-md-6 mb-4">
                      <p className="subheading mb-0">Highly Affectionate</p>
                      <audio controls>
                        <source
                          src="https://res.cloudinary.com/ds1cf1g5j/video/upload/v1576462678/commercials/spot-5_wggzfy.mp3"
                          type="audio/mp3"
                        />
                        <track srclang="eng" label="English" kind="captions" />
                        Your browser does not support the audio element.
                      </audio>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ScheduleSection />
      </Layout>
    )
  }
}
export default AboutPage

export const aboutPageQuery = graphql`
  query aboutPageQuery {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
  }
`
